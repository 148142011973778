import logo from './Untitled Logo-27.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className=''>A blank canvas. Create what you want. More coming soon...</p>
      </header>
    </div>
  );
}

export default App;
